/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FaceScanBaseService } from '../face-scan-base-service';
import { FaceScanApiConfiguration } from '../face-scan-api-configuration';
import { FaceScanStrictHttpResponse } from '../face-scan-strict-http-response';

import { IntentConfigModel } from '../models/intent-config-model';
import { MeasurementModel } from '../models/measurement-model';
import { ProfileResponseModel } from '../models/profile-response-model';
import { SdkConfigModel } from '../models/sdk-config-model';
import { sessionAccessTokenPost } from '../fn/session/session-access-token-post';
import { SessionAccessTokenPost$Params } from '../fn/session/session-access-token-post';
import { sessionHistorySystemIdentifierIdGet } from '../fn/session/session-history-system-identifier-id-get';
import { SessionHistorySystemIdentifierIdGet$Params } from '../fn/session/session-history-system-identifier-id-get';
import { sessionIntentScanConfigSessionIdGet } from '../fn/session/session-intent-scan-config-session-id-get';
import { SessionIntentScanConfigSessionIdGet$Params } from '../fn/session/session-intent-scan-config-session-id-get';
import { sessionMemberLastScanGetSystemIdentifierIdGet } from '../fn/session/session-member-last-scan-get-system-identifier-id-get';
import { SessionMemberLastScanGetSystemIdentifierIdGet$Params } from '../fn/session/session-member-last-scan-get-system-identifier-id-get';
import { sessionRequestSessionPost } from '../fn/session/session-request-session-post';
import { SessionRequestSessionPost$Params } from '../fn/session/session-request-session-post';
import { SessionResponseModel } from '../models/session-response-model';
import { SessionResultModel } from '../models/session-result-model';
import { sessionScanCompletedSessionIdGet } from '../fn/session/session-scan-completed-session-id-get';
import { SessionScanCompletedSessionIdGet$Params } from '../fn/session/session-scan-completed-session-id-get';
import { sessionSdkConfigGet } from '../fn/session/session-sdk-config-get';
import { SessionSdkConfigGet$Params } from '../fn/session/session-sdk-config-get';
import { sessionSdkScanConfigAsyncPost } from '../fn/session/session-sdk-scan-config-async-post';
import { SessionSdkScanConfigAsyncPost$Params } from '../fn/session/session-sdk-scan-config-async-post';
import { sessionSessionHistorySystemIdentifierIdProfileIdGet } from '../fn/session/session-session-history-system-identifier-id-profile-id-get';
import { SessionSessionHistorySystemIdentifierIdProfileIdGet$Params } from '../fn/session/session-session-history-system-identifier-id-profile-id-get';
import { sessionSessionProfileSystemIdentifierIdProfileIdGet } from '../fn/session/session-session-profile-system-identifier-id-profile-id-get';
import { SessionSessionProfileSystemIdentifierIdProfileIdGet$Params } from '../fn/session/session-session-profile-system-identifier-id-profile-id-get';
import { sessionSessionResultSessionIdGet } from '../fn/session/session-session-result-session-id-get';
import { SessionSessionResultSessionIdGet$Params } from '../fn/session/session-session-result-session-id-get';
import { sessionSignalSetupGet } from '../fn/session/session-signal-setup-get';
import { SessionSignalSetupGet$Params } from '../fn/session/session-signal-setup-get';
import { sessionSubmitSdkSessionPost } from '../fn/session/session-submit-sdk-session-post';
import { SessionSubmitSdkSessionPost$Params } from '../fn/session/session-submit-sdk-session-post';
import { sessionTestNotifyCompleteTestSessionIdGet } from '../fn/session/session-test-notify-complete-test-session-id-get';
import { SessionTestNotifyCompleteTestSessionIdGet$Params } from '../fn/session/session-test-notify-complete-test-session-id-get';
import { TokenResponseModel } from '../models/token-response-model';

@Injectable({ providedIn: 'root' })
export class FaceScanSessionService extends FaceScanBaseService {
  constructor(config: FaceScanApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sessionRequestSessionPost()` */
  static readonly SessionRequestSessionPostPath = '/Session/RequestSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionRequestSessionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionRequestSessionPost$Response(params?: SessionRequestSessionPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SessionResponseModel>> {
    return sessionRequestSessionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionRequestSessionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionRequestSessionPost(params?: SessionRequestSessionPost$Params, context?: HttpContext): Observable<SessionResponseModel> {
    return this.sessionRequestSessionPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SessionResponseModel>): SessionResponseModel => r.body)
    );
  }

  /** Path part for operation `sessionSessionProfileSystemIdentifierIdProfileIdGet()` */
  static readonly SessionSessionProfileSystemIdentifierIdProfileIdGetPath = '/Session/SessionProfile/{systemIdentifierId}/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionSessionProfileSystemIdentifierIdProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSessionProfileSystemIdentifierIdProfileIdGet$Response(params: SessionSessionProfileSystemIdentifierIdProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<ProfileResponseModel>> {
    return sessionSessionProfileSystemIdentifierIdProfileIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionSessionProfileSystemIdentifierIdProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSessionProfileSystemIdentifierIdProfileIdGet(params: SessionSessionProfileSystemIdentifierIdProfileIdGet$Params, context?: HttpContext): Observable<ProfileResponseModel> {
    return this.sessionSessionProfileSystemIdentifierIdProfileIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<ProfileResponseModel>): ProfileResponseModel => r.body)
    );
  }

  /** Path part for operation `sessionSessionResultSessionIdGet()` */
  static readonly SessionSessionResultSessionIdGetPath = '/Session/SessionResult/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionSessionResultSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSessionResultSessionIdGet$Response(params: SessionSessionResultSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SessionResultModel>> {
    return sessionSessionResultSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionSessionResultSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSessionResultSessionIdGet(params: SessionSessionResultSessionIdGet$Params, context?: HttpContext): Observable<SessionResultModel> {
    return this.sessionSessionResultSessionIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SessionResultModel>): SessionResultModel => r.body)
    );
  }

  /** Path part for operation `sessionSessionHistorySystemIdentifierIdProfileIdGet()` */
  static readonly SessionSessionHistorySystemIdentifierIdProfileIdGetPath = '/Session/SessionHistory/{systemIdentifierId}/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionSessionHistorySystemIdentifierIdProfileIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSessionHistorySystemIdentifierIdProfileIdGet$Response(params: SessionSessionHistorySystemIdentifierIdProfileIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<SessionResultModel>>> {
    return sessionSessionHistorySystemIdentifierIdProfileIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionSessionHistorySystemIdentifierIdProfileIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSessionHistorySystemIdentifierIdProfileIdGet(params: SessionSessionHistorySystemIdentifierIdProfileIdGet$Params, context?: HttpContext): Observable<Array<SessionResultModel>> {
    return this.sessionSessionHistorySystemIdentifierIdProfileIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<SessionResultModel>>): Array<SessionResultModel> => r.body)
    );
  }

  /** Path part for operation `sessionHistorySystemIdentifierIdGet()` */
  static readonly SessionHistorySystemIdentifierIdGetPath = '/Session/History/{systemIdentifierId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionHistorySystemIdentifierIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionHistorySystemIdentifierIdGet$Response(params: SessionHistorySystemIdentifierIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<SessionResultModel>>> {
    return sessionHistorySystemIdentifierIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionHistorySystemIdentifierIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionHistorySystemIdentifierIdGet(params: SessionHistorySystemIdentifierIdGet$Params, context?: HttpContext): Observable<Array<SessionResultModel>> {
    return this.sessionHistorySystemIdentifierIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<SessionResultModel>>): Array<SessionResultModel> => r.body)
    );
  }

  /** Path part for operation `sessionAccessTokenPost()` */
  static readonly SessionAccessTokenPostPath = '/Session/AccessToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionAccessTokenPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionAccessTokenPost$Response(params?: SessionAccessTokenPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<TokenResponseModel>> {
    return sessionAccessTokenPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionAccessTokenPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionAccessTokenPost(params?: SessionAccessTokenPost$Params, context?: HttpContext): Observable<TokenResponseModel> {
    return this.sessionAccessTokenPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<TokenResponseModel>): TokenResponseModel => r.body)
    );
  }

  /** Path part for operation `sessionSubmitSdkSessionPost()` */
  static readonly SessionSubmitSdkSessionPostPath = '/Session/SubmitSDKSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionSubmitSdkSessionPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionSubmitSdkSessionPost$Response(params?: SessionSubmitSdkSessionPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SessionResultModel>> {
    return sessionSubmitSdkSessionPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionSubmitSdkSessionPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionSubmitSdkSessionPost(params?: SessionSubmitSdkSessionPost$Params, context?: HttpContext): Observable<SessionResultModel> {
    return this.sessionSubmitSdkSessionPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SessionResultModel>): SessionResultModel => r.body)
    );
  }

  /** Path part for operation `sessionTestNotifyCompleteTestSessionIdGet()` */
  static readonly SessionTestNotifyCompleteTestSessionIdGetPath = '/Session/TestNotifyCompleteTest/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionTestNotifyCompleteTestSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionTestNotifyCompleteTestSessionIdGet$Response(params: SessionTestNotifyCompleteTestSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<void>> {
    return sessionTestNotifyCompleteTestSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionTestNotifyCompleteTestSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionTestNotifyCompleteTestSessionIdGet(params: SessionTestNotifyCompleteTestSessionIdGet$Params, context?: HttpContext): Observable<void> {
    return this.sessionTestNotifyCompleteTestSessionIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sessionScanCompletedSessionIdGet()` */
  static readonly SessionScanCompletedSessionIdGetPath = '/Session/ScanCompleted/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionScanCompletedSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionScanCompletedSessionIdGet$Response(params: SessionScanCompletedSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<boolean>> {
    return sessionScanCompletedSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionScanCompletedSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionScanCompletedSessionIdGet(params: SessionScanCompletedSessionIdGet$Params, context?: HttpContext): Observable<boolean> {
    return this.sessionScanCompletedSessionIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `sessionSdkScanConfigAsyncPost()` */
  static readonly SessionSdkScanConfigAsyncPostPath = '/Session/SDKScanConfigAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionSdkScanConfigAsyncPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionSdkScanConfigAsyncPost$Response(params?: SessionSdkScanConfigAsyncPost$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SdkConfigModel>> {
    return sessionSdkScanConfigAsyncPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionSdkScanConfigAsyncPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sessionSdkScanConfigAsyncPost(params?: SessionSdkScanConfigAsyncPost$Params, context?: HttpContext): Observable<SdkConfigModel> {
    return this.sessionSdkScanConfigAsyncPost$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SdkConfigModel>): SdkConfigModel => r.body)
    );
  }

  /** Path part for operation `sessionIntentScanConfigSessionIdGet()` */
  static readonly SessionIntentScanConfigSessionIdGetPath = '/Session/IntentScanConfig/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionIntentScanConfigSessionIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionIntentScanConfigSessionIdGet$Response(params: SessionIntentScanConfigSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<IntentConfigModel>> {
    return sessionIntentScanConfigSessionIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionIntentScanConfigSessionIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionIntentScanConfigSessionIdGet(params: SessionIntentScanConfigSessionIdGet$Params, context?: HttpContext): Observable<IntentConfigModel> {
    return this.sessionIntentScanConfigSessionIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<IntentConfigModel>): IntentConfigModel => r.body)
    );
  }

  /** Path part for operation `sessionSdkConfigGet()` */
  static readonly SessionSdkConfigGetPath = '/Session/SDKConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionSdkConfigGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSdkConfigGet$Response(params?: SessionSdkConfigGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SdkConfigModel>> {
    return sessionSdkConfigGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionSdkConfigGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSdkConfigGet(params?: SessionSdkConfigGet$Params, context?: HttpContext): Observable<SdkConfigModel> {
    return this.sessionSdkConfigGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SdkConfigModel>): SdkConfigModel => r.body)
    );
  }

  /** Path part for operation `sessionMemberLastScanGetSystemIdentifierIdGet()` */
  static readonly SessionMemberLastScanGetSystemIdentifierIdGetPath = '/Session/MemberLastScanGet/{systemIdentifierId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionMemberLastScanGetSystemIdentifierIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionMemberLastScanGetSystemIdentifierIdGet$Response(params: SessionMemberLastScanGetSystemIdentifierIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<SessionResultModel>> {
    return sessionMemberLastScanGetSystemIdentifierIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionMemberLastScanGetSystemIdentifierIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionMemberLastScanGetSystemIdentifierIdGet(params: SessionMemberLastScanGetSystemIdentifierIdGet$Params, context?: HttpContext): Observable<SessionResultModel> {
    return this.sessionMemberLastScanGetSystemIdentifierIdGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<SessionResultModel>): SessionResultModel => r.body)
    );
  }

  /** Path part for operation `sessionSignalSetupGet()` */
  static readonly SessionSignalSetupGetPath = '/Session/SignalSetup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionSignalSetupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSignalSetupGet$Response(params?: SessionSignalSetupGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<Array<MeasurementModel>>> {
    return sessionSignalSetupGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionSignalSetupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionSignalSetupGet(params?: SessionSignalSetupGet$Params, context?: HttpContext): Observable<Array<MeasurementModel>> {
    return this.sessionSignalSetupGet$Response(params, context).pipe(
      map((r: FaceScanStrictHttpResponse<Array<MeasurementModel>>): Array<MeasurementModel> => r.body)
    );
  }

}
