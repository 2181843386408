import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TokenService } from '../token/token.service';
import { LoadingService } from '../loading-service/loading.service';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { WearableService } from '../wearable-service/wearable.service';
import { EventService } from '../events/event.service';
import { VitalScoreApiConfiguration } from 'src/app/api/proxy/vitalscore/vital-score-api-configuration';
import { FaceScanApiConfiguration } from 'src/app/api/proxy/facescan/face-scan-api-configuration';
import { WearablesApiConfiguration } from 'src/app/api/proxy/wearables/wearables-api-configuration';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { HealthScoreService } from '../health-score-service/health-score.service';
import { MyProfileService } from '../my-profile-service/my-profile.service';
import { UserInfoService } from '../auth-service/user-info-service';
import { TeamsService } from '../teams-service/teams.service';
import { AuthenticationApiConfiguration } from 'src/app/api/proxy/auth/authentication-api-configuration';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { AuthService } from '../auth-service/auth.service';
import { NotifyConsumerService, NotifyPushNotifyProviderService } from 'src/app/api/proxy/notify/notify-services';
import { IAppEnvironment } from 'src/app/api/proxy/app-environment/app-environment-models';
import { VirtualHealthLibraryApiConfiguration } from 'src/app/api/proxy/virtualHealthLibrary/virtual-health-library-api-configuration';
import { OrganizationApiConfiguration } from 'src/app/api/proxy/organization/organization-api-configuration';
import { TranslatorService } from '../translator-service/translator.service';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class ServicesStartupService {


  constructor(
    public alertController: AlertController,
    private loadingService: LoadingService,
    private tokenService: TokenService,
    private _EnvironmentConfigService: EnvironmentConfigService,
    private _HealthScoreService: HealthScoreService,
    private _MyProfileService: MyProfileService,
    private _StorageService: SecureStorageService,
    private _userInfoService: UserInfoService,
    private _teamsService: TeamsService,
    private _authService: AuthService,
    private _translatorService: TranslatorService
  ) {


    var redirectRoute = this.getParameterByName('route');
    if (redirectRoute) {
      var r = {
        route: redirectRoute
      };
      var jsonRoute = JSON.stringify(r);
      setTimeout(async () => { 
        await this._StorageService.set(SecureStorageKey.RedirectRoute, jsonRoute) 
      }, 0);
    }

  }


  public async initServices(): Promise<void> {
    if(Capacitor.getPlatform() === "android")
      await SplashScreen.hide();
    
    await SplashScreen.show({ autoHide: true });
    try {
      
      await this._EnvironmentConfigService.EnsureNetworkConfiguration("servicestartup-initServices");
      this.loadingService.setTranslationService(this._translatorService);
      await this._teamsService.initService();

      var inTeams = await this._teamsService.inTeams();
      if (!inTeams) {
             await this._authService.initAuthService();
      }
 
       var usr = await this._userInfoService.getUserInfo();
      if (usr && usr.access_token) {
        var tokenExpired = await this.tokenService.tokenExpired();
        if (!tokenExpired) { 
          await Promise.all([
            this._MyProfileService.initService(),
            this._HealthScoreService.initService('initServices')
          ]); 
        }
      }
    } finally {
      await SplashScreen.hide();
    }
  }

  public async resumeApp(): Promise<boolean> {
      await this._EnvironmentConfigService.EnsureNetworkConfiguration("servicestartup-resumeApp");

      var inTeams = await this._teamsService.inTeams();
      if (!inTeams) {
        await this._authService.initAuthService();
      }

      var usr = await this._userInfoService.getUserInfo();
      if (usr && usr.access_token) {
        var tokenExpired = await this.tokenService.tokenExpired();

        if (!tokenExpired) {
          await Promise.all([
            this._HealthScoreService.initService('resumeApp'),
            this._MyProfileService.initService()
          ]);
        }
      }
    return true;
  }

  public async postLoginInit(): Promise<boolean> {
    await Promise.all([
      this._HealthScoreService.initService('postLogin'),
      this._MyProfileService.initService()
    ]);

    return true;
  }



  getParameterByName(name: string, url = window.location.href): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
