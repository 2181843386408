import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserData } from '../auth-service/user-data.model';
import { AppAccessType, HealthScore, ProfileModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { LastFaceScan } from '../face-scan-service/LastFaceScan';
import { GoalsModel, SessionConfigResponseModel, StatisticsModel } from 'src/app/api/proxy/wearables/wearables-models';
import { NotificationHistoryItem } from 'src/app/api/proxy/notify/notify-models';
import { IAppEnvironment } from 'src/app/api/proxy/app-environment/app-environment-models';
import { ILocalAppEnvironment } from '../environment-config-service/environment-config.service';

@Injectable({
	providedIn: 'root'
})
/**
 * This is the event service class
 * @deprecated This class is deprecated and event handling should be done in their respective services.
 */
export class EventService {
	public healthScoreUpdated: EventEmitter<HealthScore> = new EventEmitter<HealthScore>();
	public faceScanUpdated: EventEmitter<LastFaceScan> = new EventEmitter<LastFaceScan>();
	public myProfileUpdated: EventEmitter<ProfileModel> = new EventEmitter<ProfileModel>();
	public wearableDialySummaryUpdated: EventEmitter<StatisticsModel> = new EventEmitter<StatisticsModel>();
	public wearableGoalsUpdated: EventEmitter<GoalsModel> = new EventEmitter<GoalsModel>();
	public wearableConfigUpdted: EventEmitter<SessionConfigResponseModel> = new EventEmitter<SessionConfigResponseModel>();
	public appDeeplinkEvent: EventEmitter<string> = new EventEmitter<string>();
	public appPaused: EventEmitter<any> = new EventEmitter<any>();
	public appResumed: EventEmitter<any> = new EventEmitter<any>();
	public pageFocused: EventEmitter<AppAccessType> = new EventEmitter<AppAccessType>();
	public notificationCount: EventEmitter<number> = new EventEmitter<number>();
	public notificationHistoruUpdated: EventEmitter<Array<NotificationHistoryItem>> = new EventEmitter<Array<NotificationHistoryItem>>();
	public environmentLoaded: EventEmitter<ILocalAppEnvironment> = new EventEmitter<ILocalAppEnvironment>();
	public userUpdated: EventEmitter<UserData|null> = new EventEmitter<UserData|null>();
}
