enum SecureKeys {
	CurrentRegion = 'CurrentRegion',
	CurrentUser = 'CurrentUser',
	MyProfile = 'MyProfile',
	FaceScanResults = 'FaceScanResults',
	MyHealthScore = 'MyHealthScore',
	MediaCategories = 'MediaCategories',
	LatestArticles = 'LatestArticles',
	WearablesSummary = 'WearablesSummary',
	EnvironmentSettings = 'EnvironmentSettings',
	WearablesGoals = 'WearablesGoals',
	RedirectRoute = 'RedirectRoute',
	WearableConfig = 'WearableConfig',
	LocalUser = 'LocalUser',
	CurrentScanSessionId = 'CurrentScanSessionId',
	NotificationSummary = 'NotificationSummary',
	BiometricLogin = 'BiometricLogin',
	RegistrationModel = 'RegistrationModel',
	DefualtOrginization ='DefualtOrginization',
	NotificationPermissionCheck = 'NotificationPermissionCheck',
	LastBackfillCheck = 'LastBackfillCheck',
	LastWearableConfigCheck = 'LastWearableConfigCheck',
	LastEnvironmentFetchTime = 'LastEnvironmentFetchTime',
	NextHealthScoreRefresh = 'NextHealthScoreRefresh',

}

export const SecureStorageKey = {  ...SecureKeys };
export type SecureStorageKey = SecureKeys;
