import { Injectable } from '@angular/core';
import { UserInfoService } from '../auth-service/user-info-service';
import { SystemFunctionType } from 'src/app/api/proxy/auth/authentication-models';
import { VitalScoreDashboardService } from 'src/app/api/proxy/vitalscore/vitalscore-services';
import { WearablesDashboardService } from 'src/app/api/proxy/wearables/wearables-services';
import { FaceScanDashboardService } from 'src/app/api/proxy/facescan/facescan-services';
import { catchError, firstValueFrom, of } from 'rxjs';
import { DashboardItemModel, DashboardSummaryType, OrganizationItemModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { PromptService } from '../promtp-service/prompt.service';
import { DashboardDeviceItemModel, DashboardPhysicalItemModel, DashboardSleepItemModel } from 'src/app/api/proxy/wearables/wearables-models';
import { DashboardHealthCheckItemModel } from 'src/app/api/proxy/facescan/facescan-models';
import { DashboardDateSelectionType } from './DashboardDateSelectionType';
import { TranslatorService } from '../translator-service/translator.service';
import { Network } from '@capacitor/network';





@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  private OrganizationList: OrganizationItemModel[] | null = null;

  constructor(
    private _UserInfoService: UserInfoService,
    private _VitalScoreDashboardService: VitalScoreDashboardService,
    private _WearablesDashboardService: WearablesDashboardService,
    private _FaceScanDashboardService: FaceScanDashboardService,
    private _promptService: PromptService,
    private _TranslatorService: TranslatorService
  ) {


  }

  public async userIsAllowed(): Promise<boolean> {    
    return await this._UserInfoService.userIdAllowed(SystemFunctionType.VivascoreGroupDashboard);
  }


  calculateDaysBetweenDates(fromDate: Date, toDate: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    const diffDays = Math.round(Math.abs((toDate.getTime() - fromDate.getTime()) / oneDay));
    return diffDays;
  }

  // Add the calculateMonthsBetweenDates method in your class
  calculateMonthsBetweenDates(fromDate: Date, toDate: Date): number {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    return (toYear - fromYear) * 12 + (toMonth - fromMonth);
  }

  public getSelectionOptions(): DashboardDateSelectionType[] {
    const options: DashboardDateSelectionType[] = [];

    // Get yesterday's date and set the time to 23:59:59
    let yesterDay = new Date();
    yesterDay.setDate(yesterDay.getDate() - 1);
    yesterDay.setHours(23, 59, 59, 999); // Set hours, minutes, seconds, and milliseconds

    // Populate with example data
    options.push(Object.assign(new DashboardDateSelectionType(), {
      SelectionName: this._TranslatorService.translateText('dashboard-service.date-selection-type.last-seven-days'),
      FromDate: new Date(new Date().setDate(yesterDay.getDate() - 7)),
      ToDate: yesterDay,
      SummaryType: DashboardSummaryType.Daily,
      HorizontalItems: 7
    }));

    options.push(Object.assign(new DashboardDateSelectionType(), {
      SelectionName: this._TranslatorService.translateText('dashboard-service.date-selection-type.month-to-date-daily'),
      FromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      ToDate: yesterDay,
      SummaryType: DashboardSummaryType.Daily,
      HorizontalItems: this.calculateDaysBetweenDates(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        yesterDay
      )
    }));

    options.push(Object.assign(new DashboardDateSelectionType(), {
      SelectionName: this._TranslatorService.translateText('dashboard-service.date-selection-type.last-month-daily'),
      FromDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      ToDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      SummaryType: DashboardSummaryType.Daily,
      HorizontalItems: this.calculateDaysBetweenDates(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        yesterDay
      )
    }));

    options.push(Object.assign(new DashboardDateSelectionType(), {
      SelectionName: this._TranslatorService.translateText('dashboard-service.date-selection-type.last-month-weekly'),
      FromDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      ToDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      SummaryType: DashboardSummaryType.Weekly,
      HorizontalItems: 5
    }));

    options.push(Object.assign(new DashboardDateSelectionType(), {
      SelectionName: this._TranslatorService.translateText('dashboard-service.date-selection-type.last-twelve-weeks-weekly'),
      FromDate: new Date(new Date().setDate(yesterDay.getDate() - (7 * 12))),
      ToDate: yesterDay,
      SummaryType: DashboardSummaryType.Weekly,
      HorizontalItems: 12
    }));

    options.push(Object.assign(new DashboardDateSelectionType(), {
      SelectionName: this._TranslatorService.translateText('dashboard-service.date-selection-type.this-year-monthly'),
      FromDate: new Date(new Date().getFullYear(), 0, 1),
      ToDate: new Date(),
      SummaryType: DashboardSummaryType.Monthly,
      HorizontalItems: this.calculateMonthsBetweenDates(
        new Date(new Date().getFullYear(), 0, 1),
        new Date()
      )
    }));


    return options;
  }

  public async organizationList(): Promise<OrganizationItemModel[] | null> {

    if (this.OrganizationList) {
      return this.OrganizationList;
    }
    try {
      const response = await firstValueFrom(
        this._VitalScoreDashboardService.dashboardOrganizationListGet()
      );

      if (response != null && response.length > 0) {
        this.OrganizationList = response;
      }
      return this.OrganizationList;

    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }
  //Vivascore

  public async userProfiles(organizationId: string, dateSelection: DashboardDateSelectionType): Promise<DashboardItemModel[] | null> {
    try {
      const response = await firstValueFrom(
        this._VitalScoreDashboardService.dashboardUserProfileOrganizationIdPost({
          organizationId: organizationId,
          body: {
            startDate: dateSelection.FromDate.toISOString(),
            endDate: dateSelection.ToDate.toISOString(),
            summaryType: dateSelection.SummaryType
          }
        })
      );
      return response;
    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }

  public async healthScoreAsync(organizationId: string, dateSelection: DashboardDateSelectionType): Promise<DashboardItemModel[] | null> {
    try {
      const response = await firstValueFrom(
        this._VitalScoreDashboardService.dashboardHealthScoreOrganizationIdPost({
          organizationId: organizationId,
          body: {
            startDate: dateSelection.FromDate.toISOString(),
            endDate: dateSelection.ToDate.toISOString(),
            summaryType: dateSelection.SummaryType
          }
        })
      );
      return response;
    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }

  public async timeInAppAsync(organizationId: string, dateSelection: DashboardDateSelectionType): Promise<DashboardItemModel[] | null> {
    try {
      const response = await firstValueFrom(
        this._VitalScoreDashboardService.dashboardTimeInAppOrganizationIdPost({
          organizationId: organizationId,
          body: {
            startDate: dateSelection.FromDate.toISOString(),
            endDate: dateSelection.ToDate.toISOString(),
            summaryType: dateSelection.SummaryType
          }
        })
      );
      return response;
    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }

  //Wearables
  public async deviceSummaryAsync(organizationId: string, dateSelection: DashboardDateSelectionType): Promise<DashboardDeviceItemModel[] | null> {
    try {
      const response = await firstValueFrom(
        this._WearablesDashboardService.dashboardDeviceSummaryOrganizationIdPost({
          organizationId: organizationId,
          body: {
            startDate: dateSelection.FromDate.toISOString(),
            endDate: dateSelection.ToDate.toISOString(),
            summaryType: dateSelection.SummaryType
          }
        })
      );
      return response;
    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }

  public async physicalSummaryAsync(organizationId: string, dateSelection: DashboardDateSelectionType): Promise<DashboardPhysicalItemModel[] | null> {
    try {
      const response = await firstValueFrom(
        this._WearablesDashboardService.dashboardPhysicalSummaryOrganizationIdPost({
          organizationId: organizationId,
          body: {
            startDate: dateSelection.FromDate.toISOString(),
            endDate: dateSelection.ToDate.toISOString(),
            summaryType: dateSelection.SummaryType
          }
        })
      );
      return response;
    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }

  public async sleepSummaryAsync(organizationId: string, dateSelection: DashboardDateSelectionType): Promise<DashboardSleepItemModel[] | null> {
    try {
      const response = await firstValueFrom(
        this._WearablesDashboardService.dashboardSleepSummaryOrganizationIdPost({
          organizationId: organizationId,
          body: {
            startDate: dateSelection.FromDate.toISOString(),
            endDate: dateSelection.ToDate.toISOString(),
            summaryType: dateSelection.SummaryType
          }
        })
      );
      return response;
    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }

  //healthCheck
  public async scanSummaryAsync(organizationId: string, dateSelection: DashboardDateSelectionType): Promise<DashboardHealthCheckItemModel[] | null> {
    try {
      const response = await firstValueFrom(
        this._FaceScanDashboardService.dashboardOrganizationScanSummaryOrganizationIdPost({
          organizationId: organizationId,
          body: {
            startDate: dateSelection.FromDate.toISOString(),
            endDate: dateSelection.ToDate.toISOString(),
            summaryType: dateSelection.SummaryType
          }
        })
      );
      return response;
    } catch (error) {
      await this._promptService.throwNewtorkError(error);
      return null;
    }
  }


}

