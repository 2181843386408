import { Injectable } from '@angular/core';
import { AlertController, IonicSafeString, ModalController, ToastController } from '@ionic/angular';
import { LoadingService } from '../loading-service/loading.service';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { VivaInfoPopupComponent } from 'src/app/controls/viva-info-popup/viva-info-popup.component';
import { TranslatorService } from '../translator-service/translator.service';


@Injectable({
  providedIn: 'root',
})
export class PromptService {

  constructor(protected _alertController: AlertController,
    private _toast: ToastController,
    private _loading: LoadingService,
    private _systemLog: SystemLogService,
    protected modalController: ModalController,
    private _TranslatorService: TranslatorService

  ) { }

  public async throwError(
    errorObject: any,
    statusText: string,
    title: string = 'Error'
  ) {

    if (errorObject) {
      this._systemLog.logError(errorObject, statusText, title);
    }
    const alert = await this._alertController.create({
      header: title,
      message: statusText,
      buttons: ['OK'],
      cssClass: 'custom-error-alert',//
    });

    await this._loading.closeAllLoaders();
    await alert.present();
    await alert.onDidDismiss();

  }

  public async showError(err: string, title: string = 'Error') {
    const alert = await this._alertController.create({
      header: title,
      message: err,
      buttons: ['OK'],
      cssClass: 'custom-error-alert',//
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  public async throwNewtorkError(err: any) {
    this._systemLog.logError(err, 'Network error', 'Network error');
    await this._loading.closeAllLoaders();

    if (err && err.error
      && err.error.message) {

      if (err.error.message === 'Unknown... Something went wrong...') {       
        await this.showToastMessage(this._TranslatorService.translateText('prompt-service.server-error'));
      } else {

        const alert = await this._alertController.create({
          header: 'Server error',
          message: err.error.message,
          buttons: ['OK'],
          cssClass: 'custom-error-alert',//
        });

        await alert.present();
        await alert.onDidDismiss();
      }
    } else {
  
      await this.showToastMessage(this._TranslatorService.translateText('prompt-service.server-not-reachable'));
    }
  }

  public async showMessage(
    statusText: string,
    title: string = ''
  ) {
    const modal = await this.modalController.create({
      component: VivaInfoPopupComponent,
      componentProps: {
        message: statusText
      },
      cssClass: 'custom-info-modal'
    });
  
    await this._loading.closeAllLoaders();
    await modal.present();
    await modal.onDidDismiss();
  }
  
  public async showToastMessage(message: string, duration: number = 2000) {
    const toast = await this._toast.create({
      message: message,
      duration: duration,
      position: 'bottom'
    });

    await toast.present();
  }

  private isShowingWarningMessage: boolean = false;
  public async showWarningMessage(
    message: string
  ) {

    if (this.isShowingWarningMessage)
      return;

    this.isShowingWarningMessage = true;
    try {
      const alert = await this._alertController.create({
        header: 'Warning',
        message: message,
        buttons: ['OK'],
        cssClass: 'custom-error-warning',//
      });

      await this._loading.closeAllLoaders();
      await alert.present();

      await alert.onDidDismiss();
    } finally {
      this.isShowingWarningMessage = false;
    }

  }

  public async confirmationMessage(message: string): Promise<boolean> {

    return new Promise<boolean>(async (resolve) => {
      let alert = await this._alertController.create({
        cssClass: 'custom-confirmation-alert',//
        header: 'Confirmation',
        message: message,
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          },
          {
            text: 'Yes',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
      await alert.present();
      await alert.onDidDismiss();
    });
  }

  public async showNetworkConnectionError(){

    await this.showWarningMessage(this._TranslatorService.translateText('prompt-service.server-not-reachable'));
  }

}