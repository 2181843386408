import { Injectable } from '@angular/core';
import { StorageKey } from '../storage/models/storage-key.enum';
import { firstValueFrom } from 'rxjs';
import { AccessTokenModel, LinkOrganizationModel, LinkedOrganizationModel, OrganizationCodeModel, ProfileModel, RegisterModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { SecureStorageService } from '../storage/secure-storage.service';
import { PromptService } from '../promtp-service/prompt.service';
import { EventService } from '../events/event.service';
import { LoadingService } from '../loading-service/loading.service';
import { differenceInYears } from 'date-fns';
import { VitalScoreProfileService } from 'src/app/api/proxy/vitalscore/vitalscore-services';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class MyProfileService {


  constructor(private _StorageService: SecureStorageService,
    protected _vitalScoreProfileService: VitalScoreProfileService,
    protected _PromptService: PromptService,
    protected _events: EventService,
    protected _loadingService: LoadingService
  ) { }
  private blocking = false;
  public async GetProfile(forced: boolean = false): Promise<ProfileModel | null> {
    var myProfile = await this._StorageService.get(StorageKey.MyProfile);
    var model: ProfileModel | null = null;
    if (myProfile) {
      try {
        model = JSON.parse(myProfile);
      } catch {
        model = null
      }
    }

    if (model) {
      if (model.heightInCm) {
        model.heightInCm = Math.floor(model.heightInCm);
      }

      if (model.weightInKg) {
        model.weightInKg = Math.floor(model.weightInKg);
      }

      this._loadingService.registerSilentUrl(VitalScoreProfileService.ProfileGetProfileGetPath);
      this._events.myProfileUpdated.emit(model);
      if (!forced) {
        return model;
      }

      const status = await Network.getStatus();
      if (status.connected === false) {
        return model;
      }

    } else {
      this._loadingService.removeSilentUrl(VitalScoreProfileService.ProfileGetProfileGetPath);
    }

    if (this.blocking)
      return model;
    this.blocking = true;
    try {

      if (!model || !model.profileExists) {
        var promise = firstValueFrom(this._vitalScoreProfileService.profileGetProfileGet());
        promise.then(async (result) => {

          if (result) {
            if (result.heightInCm) {
              result.heightInCm = Math.floor(result.heightInCm);
            }

            if (result.weightInKg) {
              result.weightInKg = Math.floor(result.weightInKg);
            }
          }

          model = result;
          await this._StorageService.set(StorageKey.MyProfile, JSON.stringify(result));
          this._events.myProfileUpdated.emit(result);
          return result;
        }).catch(async (err) => await this._PromptService.throwNewtorkError(err));
        await promise;
      }
    } finally {
      this.blocking = false;
    }
    return model;
  }

  public async SaveProfile(profile: ProfileModel): Promise<boolean> {

    if (profile) {
      if (profile.heightInCm) {
        profile.heightInCm = Math.floor(profile.heightInCm);
      }

      if (profile.weightInKg) {
        profile.weightInKg = Math.floor(profile.weightInKg);
      }
    }

    var promise = firstValueFrom(this._vitalScoreProfileService.profileSaveProfilePost({ body: profile }));
    promise.then(async (result) => {
      await this._StorageService.set(StorageKey.MyProfile, JSON.stringify(result));
      this._events.myProfileUpdated.emit(result);
      return true;
    }).catch(async (err) => await this._PromptService.throwNewtorkError(err));

    var promiseResult = await promise;
    if (promiseResult)
      return true;
    else
      return false;
  }

  public getAge(profile: ProfileModel): number {
    return differenceInYears(new Date(), new Date(profile.dateOfBirth));
  }

  public async linkOrganization(linkCode: string): Promise<LinkedOrganizationModel[]> {

    var myProfile = await this._StorageService.get(StorageKey.MyProfile);
    var model: ProfileModel | null = null;
    if (myProfile) {
      try {
        model = JSON.parse(myProfile);
      } catch {
        model = null
      }
    }

    var request: LinkOrganizationModel = {
      shortCode: linkCode
    }
    var promise = firstValueFrom(this._vitalScoreProfileService.profileOrganizationLinkPost({ body: request }));
    promise.then(async (result) => {
      if (model) {
        model.linkedOrganizations = result;
        model.organizationLinked = result && result.length > 0;
        model.profileExists = true;
        await this._StorageService.set(StorageKey.MyProfile, JSON.stringify(model));
        this._events.myProfileUpdated.emit(model);
      }
      return result;
    }).catch(async (err) => await this._PromptService.throwError(err, 'Unable to link organization - please verify you have the correct code.'));

    return await promise;
  }

  public async unlinkOrganization(linkId: string): Promise<LinkedOrganizationModel[]> {
    var myProfile = await this._StorageService.get(StorageKey.MyProfile);
    var model: ProfileModel | null = null;
    if (myProfile) {
      try {
        model = JSON.parse(myProfile);
      } catch {
        model = null
      }
    }

    var promise = firstValueFrom(this._vitalScoreProfileService.profileOrganizationUnlinkIdDelete({ id: linkId }));
    promise.then(async (result) => {
      if (model) {
        model.linkedOrganizations = result;
        model.organizationLinked = result && result.length > 0;
        model.profileExists = true;
        await this._StorageService.set(StorageKey.MyProfile, JSON.stringify(model));
        this._events.myProfileUpdated.emit(model);
      }
      return result;
    }).catch(async (err) =>
      await this._PromptService.throwError(err, 'The server was unable to process your request. Please try again later. Profile')
    );
    return await promise;
  }

  public async organizationByShortCode(shortCode: string): Promise<OrganizationCodeModel | null> {
    this._loadingService.registerSilentUrl(VitalScoreProfileService.ProfileGetOrganizationByLinkCodeLinkCodeGetPath);

    try {
      const orgObservable = this._vitalScoreProfileService.profileGetOrganizationByLinkCodeLinkCodeGet({ linkCode: shortCode });
      const org = await firstValueFrom(orgObservable);

      if (org) {
        return org;
      } else {
        console.error('No organization found for the given short code:', shortCode);
        return null;
      }
    } catch (error) {
      console.error('Error fetching organization:', error);
      return null;
    }
  }

  public async initService(): Promise<void> {
    await this.GetProfile();
  }
}
