<ion-menu contentId="main-content">
  <ion-header [translucent]="true">
    <ion-toolbar>
      <ion-title>{{ 'main-menu-component.header.title' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding menu-border">
    <ion-list>
      <!--  
      <ion-item>
        <ion-icon name="person-circle-outline" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="gotoV2()">
          Preview V2
        </ion-button>
      </ion-item>
        <ion-item>
        <ion-icon name="person-circle-outline" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="gotoControlTest()">
          Controls Test
        </ion-button>
      </ion-item>-->

      <ion-item>
        <ion-icon name="person-circle-outline" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="myProfile()">
          {{ 'main-menu-component.items.profile' | translate }}
        </ion-button>
      </ion-item>
      <ion-item *ngIf="isDashboardAllowed">
        <ion-icon name="podium" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="dashboard()">{{'main-menu-component.items.dashboard' | translate
          }}</ion-button>
      </ion-item>
      <!--
      <ion-item>
        <ion-icon name="person-circle-outline" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="mediaChatbot()">{{'main-menu-component.items.chatbot' | translate
          }}</ion-button>
      </ion-item>
      -->
      <ion-item>
        <ion-icon name="shield-outline" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="openUrl('https://www.alulatechnologies.com/privacy-policy')">
          {{ 'main-menu-component.items.privacy-policy' | translate }}
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-icon name="shield-checkmark-outline" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="openUrl('https://www.alulatechnologies.com/terms-and-conditions')">
          {{ 'main-menu-component.items.terms-conditions' | translate }}
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-icon name="call-outline" slot="start" size="large"></ion-icon>
        <ion-button class="menu-button" (click)="openUrl('https://www.alulatechnologies.com/contact')">
          {{ 'main-menu-component.items.contact-us' | translate }}
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-icon name="trash-outline" slot="start" size="large"></ion-icon>
        <ion-button color="tertiary" class="menu-button" (click)="deleteAccount()">
          {{ 'main-menu-component.items.delete-account' | translate }}
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-icon name="log-out-outline" slot="start" size="large"></ion-icon>
        <ion-button color="medium" class="menu-button" (click)="logout()">
          {{ 'main-menu-component.items.logout' | translate }}
        </ion-button>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>