import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { EventService } from '../events/event.service';
import { isAfter, addSeconds } from 'date-fns';
import { VivaSpinnerComponent } from 'src/app/controls/viva-spinner/viva-spinner.component';
import { TranslatorService } from '../translator-service/translator.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  currentLoader: HTMLIonLoadingElement | undefined | null;
  loadingMap: Map<string, boolean> = new Map<string, boolean>();
  private expiryDate: Date | null = null;
  private refreshInterval: any;
  private viewContainerRef: ViewContainerRef | null = null;

  constructor(
    private loadingCtrl: LoadingController,
    private events: EventService,
  ) {
    this.events.appPaused.subscribe(() => {
      this.clearRefresh();
    });

    this.events.appResumed.subscribe(() => {
      this.setupRefreshTimer();
    });
  }

  private silentUrls: string[] = [];
  private maxLoadingWaitTimeSeconds = 30;
  private loadingDisabled = false;

  initialize(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
    this.setupRefreshTimer();
  }

  clearRefresh() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  setupRefreshTimer() {
    this.clearRefresh();

    this.refreshInterval = setInterval(async () => {
      const checkDt = new Date();

      if (this.expiryDate && isAfter(checkDt, this.expiryDate)) {
        await this.closeAllLoaders();
        await this.checkClearBusy();
      }
    }, 5000);
  }

  disableLoading() {
    this.loadingDisabled = true;
  }

  enableLoading() {
    this.loadingDisabled = false;
  }

  async setLoading(loading: boolean, url: string): Promise<void> {
    if (loading && this.isSilentUrl(url) || this.loadingDisabled) {
      return;
    }

    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }

    if (loading) {
      this.loadingMap.set(url, loading);
    } else {
      if (this.loadingMap.has(url)) {
        this.loadingMap.delete(url);
      }
    }

    await this.checkClearBusy();
  }

  async closeAllLoaders() {
    this.loadingMap.clear();
    await this.checkClearBusy();
    // Ensure all loaders are closed before returning
    await this.ensureAllLoadersClosed();
  }
  
  private async ensureAllLoadersClosed() {
    let loader = await this.loadingCtrl.getTop();
    while (loader) {
      try {
        await loader.dismiss();
        loader = await this.loadingCtrl.getTop();
      } catch {
        loader = undefined;
      }
    }
    this.currentLoader = null;
  }
  
  private getLoadingDescription(): string {
   /*    var result = '';
      this.loadingMap.forEach((value, key) => {
        if (result.length > 0)
          result += '\n';
        result += this.getLastUrlSegments(key,3);
      });
      return result;
*/

    if(!this.translator) return "Busy Please Wait";
    return this.translator.translateText('loading-service.busy-please-wait');
  }

  private translator?: TranslatorService;

  public setTranslationService(translator: TranslatorService) {
    this.translator = translator;
  }

  getLastUrlSegments(url: string, segmentCount: number): string {
    const segments = url.split('/').filter(segment => segment.length > 0);
    const lastSegments = segments.slice(-segmentCount);
    return lastSegments.join('/');
  }

  getLastUrlSegment(url: string) {
    if (!url) return url;
    if (!url.includes('/')) return url;
    try {
      var lastSegment = new URL(url).pathname.split('/').filter(Boolean).pop();
      if (!lastSegment) lastSegment = '';

      if (this.isUUID(lastSegment)) {
        var arr = new URL(url).pathname.split('/').filter(Boolean);
        if (arr.length > 1) return arr[arr.length - 2];
      }
      return lastSegment;
    } catch {
      return url;
    }
  }

  isUUID(uuid: string) {
    let s = "" + uuid;
    var matchArrayy = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (matchArrayy === null) {
      return false;
    }
    return true;
  }

  async checkCloseLoading(){
    if (this.loadingMap.size <= 0) {
      var loader = await this.loadingCtrl.getTop();

      var cnt = 100;
      while (loader && cnt) {
        cnt--;
        try {
          await loader.dismiss();
          loader = await this.loadingCtrl.getTop();
        } catch { 
        }
      }
      this.currentLoader = null;
    }else{
      setTimeout(async () => {
        await this.checkCloseLoading();
      }, 1000);
    }
  }

  async checkClearBusy() {
    if (this.loadingMap.size <= 0) {
      setTimeout(async () => {
       await this.checkCloseLoading(); //protect against flashing loaders
      }, 100);
    } else {
      var loadDescription = this.getLoadingDescription();

      if (!this.currentLoader && this.viewContainerRef) {
        this.currentLoader = await this.loadingCtrl.create({
          translucent: true,
          showBackdrop: false,
          animated: false,
          backdropDismiss: false,
          message: loadDescription,
          cssClass: 'custom-loading',
        });

        // Create and insert the VivaSpinnerComponent dynamically
        const componentRef = this.viewContainerRef.createComponent(VivaSpinnerComponent);
        const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;

        const ionSpinner = this.currentLoader?.querySelector('ion-spinner');
        if (ionSpinner) {
          ionSpinner.replaceWith(domElem);
        }

        this.currentLoader.present();
        this.expiryDate = addSeconds(new Date(), this.maxLoadingWaitTimeSeconds);
      }
    }
  }

  public registerSilentUrl(url: string) {
    url = this.removeParametersFromUrl(url);
    if (!this.silentUrls.includes(url)) this.silentUrls.push(url);
  }

  public removeParametersFromUrl(url: string): string {
    const paramRegex = /{[^{}]+}/g;
    return url.replace(paramRegex, '');
  }

  public removeSilentUrl(url: string) {
    const index = this.silentUrls.indexOf(url);
    if (index !== -1) {
      this.silentUrls.splice(index, 1);
    }
  }

  public isSilentUrl(url: string): boolean {
    for (var x = 0; x < this.silentUrls.length; x++) {
      if (url.includes(this.silentUrls[x])) {
        return true;
      }
    }
    return false;
  }
}
