import { Injectable } from '@angular/core';
import * as microsoftTeams from "@microsoft/teams-js";
import { firstValueFrom } from 'rxjs';
import { PromptService } from '../promtp-service/prompt.service';
import { UserInfoService } from '../auth-service/user-info-service';
import { UserData } from '../auth-service/user-data.model';
import { addSeconds, formatISO } from 'date-fns';
import { AuthenticationUserTokenService } from 'src/app/api/proxy/auth/authentication-services';
import { LoggedInUser } from 'src/app/api/proxy/auth/authentication-models';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root',
})
export class TeamsService {
    loggedInUser: LoggedInUser | null = null;
    teamInit = false;

    constructor(private _AuthUserTokenService: AuthenticationUserTokenService,
        private _PromptService: PromptService,
        private _userService: UserInfoService
    ) {

    }




    public async initService(): Promise<boolean> {
        if (this.teamInit)
            return true;

        var inIframe = this.isInIframe();
        if (!inIframe) {
           
            return false;
        }
        await microsoftTeams.app.initialize();
        this.teamInit = microsoftTeams.app.isInitialized();
        return this.teamInit;
    }

    isInIframe(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true; // SecurityError, probably from cross-origin iframe
        }
    }

    public async inTeams(): Promise<boolean> {

        if(Capacitor.isNativePlatform())
            return false;
        
        var isIframe = this.isInIframe();
        if (!isIframe) {          
            return false;
        } 

      
        var result = this.teamInit;

        if (!result) {
            result = await this.initService();
        }

        if (!result && isIframe) {
        
            result = true;
        }

        return result;
    }

    public async getToken(): Promise<string> {
        var token = await microsoftTeams.authentication.getAuthToken();
        return token;
    }


    public async login(): Promise<boolean> {

        var token = await microsoftTeams.authentication.getAuthToken();
        if (token) {
            var tokenDetail = this.parseJwt(token);
            if (!this.loggedInUser) {
                var promise = firstValueFrom(this._AuthUserTokenService.userTokenGetUserDetailGet());
                promise.then(async (result) => {
                    this.loggedInUser = result;
                    const expTimestamp = tokenDetail.exp; // Assuming this is a Unix timestamp
                    // Get the current Unix timestamp
                    const currentTimestamp = Math.floor(Date.now() / 1000);
                    const expiresInSeconds = expTimestamp - currentTimestamp;
                    tokenDetail.expires_in = expiresInSeconds * 0.75;
                    const dt = addSeconds(new Date(), tokenDetail.expires_in);
                    const expiry = formatISO(dt);
                    var usr: UserData =
                    {
                        sub: result.b2CUserId!,
                        given_name: result.givenName!,
                        family_name: result.familyName!,
                        name: result.name!,
                        email: result.email!,
                        access_token: token,
                        refresh_token: token,
                        tokenExpiry: expiry,
                        waitTime: tokenDetail.waitTime,
                        tokenClaims: {
                            personId: result.personId,
                            sub: result.b2CUserId,
                        }
                    }
                    await this._userService.setUserInfo(usr);
                    return true;

                }).catch(async (err) => await this._PromptService.throwError(err, 'Unable to locate profile'));

                await promise;
                return true;
            }
        }
        return false;
    }

    private parseJwt(token: any) {

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    }
}
