/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VitalScoreBaseService } from '../vital-score-base-service';
import { VitalScoreApiConfiguration } from '../vital-score-api-configuration';
import { VitalScoreStrictHttpResponse } from '../vital-score-strict-http-response';

import { mediaCategoryListGet } from '../fn/media/media-category-list-get';
import { MediaCategoryListGet$Params } from '../fn/media/media-category-list-get';
import { MediaCategoryModel } from '../models/media-category-model';
import { MediaItemModel } from '../models/media-item-model';
import { mediaLatestMediaGet } from '../fn/media/media-latest-media-get';
import { MediaLatestMediaGet$Params } from '../fn/media/media-latest-media-get';
import { mediaMediaItemUrlGet } from '../fn/media/media-media-item-url-get';
import { MediaMediaItemUrlGet$Params } from '../fn/media/media-media-item-url-get';
import { mediaSearchGet } from '../fn/media/media-search-get';
import { MediaSearchGet$Params } from '../fn/media/media-search-get';

@Injectable({ providedIn: 'root' })
export class VitalScoreMediaService extends VitalScoreBaseService {
  constructor(config: VitalScoreApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mediaCategoryListGet()` */
  static readonly MediaCategoryListGetPath = '/Media/CategoryList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaCategoryListGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaCategoryListGet$Response(params?: MediaCategoryListGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<MediaCategoryModel>>> {
    return mediaCategoryListGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaCategoryListGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaCategoryListGet(params?: MediaCategoryListGet$Params, context?: HttpContext): Observable<Array<MediaCategoryModel>> {
    return this.mediaCategoryListGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<MediaCategoryModel>>): Array<MediaCategoryModel> => r.body)
    );
  }

  /** Path part for operation `mediaLatestMediaGet()` */
  static readonly MediaLatestMediaGetPath = '/Media/LatestMedia';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaLatestMediaGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaLatestMediaGet$Response(params?: MediaLatestMediaGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<MediaItemModel>>> {
    return mediaLatestMediaGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaLatestMediaGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaLatestMediaGet(params?: MediaLatestMediaGet$Params, context?: HttpContext): Observable<Array<MediaItemModel>> {
    return this.mediaLatestMediaGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<MediaItemModel>>): Array<MediaItemModel> => r.body)
    );
  }

  /** Path part for operation `mediaSearchGet()` */
  static readonly MediaSearchGetPath = '/Media/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaSearchGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaSearchGet$Response(params?: MediaSearchGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<Array<MediaItemModel>>> {
    return mediaSearchGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaSearchGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaSearchGet(params?: MediaSearchGet$Params, context?: HttpContext): Observable<Array<MediaItemModel>> {
    return this.mediaSearchGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<Array<MediaItemModel>>): Array<MediaItemModel> => r.body)
    );
  }

  /** Path part for operation `mediaMediaItemUrlGet()` */
  static readonly MediaMediaItemUrlGetPath = '/Media/MediaItemUrl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaMediaItemUrlGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaMediaItemUrlGet$Response(params?: MediaMediaItemUrlGet$Params, context?: HttpContext): Observable<VitalScoreStrictHttpResponse<string>> {
    return mediaMediaItemUrlGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediaMediaItemUrlGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaMediaItemUrlGet(params?: MediaMediaItemUrlGet$Params, context?: HttpContext): Observable<string> {
    return this.mediaMediaItemUrlGet$Response(params, context).pipe(
      map((r: VitalScoreStrictHttpResponse<string>): string => r.body)
    );
  }

}
