import {
  AfterContentInit,
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ServicesStartupService } from './services/services-startup/services-startup.service';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController } from '@ionic/angular';
import { EventService } from './services/events/event.service';
import { SystemLogService } from './services/systemlog-service/systemlog.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from './services/loading-service/loading.service';
import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true }) vcr!: ViewContainerRef;

  private initialized = false;
  public showMenu = true;

  constructor(
    private router: Router,
    private zone: NgZone,
    public servicesStartupService: ServicesStartupService,
    public navCtrl: NavController,
    private events: EventService,
    private _systemLogService: SystemLogService,
    private _loadingService: LoadingService,
    private renderer: Renderer2
  ) {


    router.events.forEach(async (event: any) => {     //After Navigation     
      if (event instanceof NavigationEnd) {
        if (event.url) {
          if (event.url.toLowerCase().startsWith("/welcome".toLowerCase())) {
            this.showMenu = false;
          }
          if (event.url.toLowerCase().startsWith("/register".toLowerCase())) {
            this.showMenu = false;
          }
          if (event.url.toLowerCase().startsWith("/face-scan-web-result".toLowerCase())) {
            this.showMenu = false;
          }
          if (event.url.toLowerCase().startsWith("/wearable-connected".toLowerCase())) {
            this.showMenu = false;
          }
          else {
            this.showMenu = true;
          }
        }
      }
    });
    this.initializeApp();
  }

  initializeApp() {
    App.removeAllListeners();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {

        const slug = event.url.split(".app").pop();
        if (slug) {
          await this.servicesStartupService.initServices();
          this.initialized = true;
          try {
            var path = slug.toLowerCase();
            if (path.includes(environment.deepLinkURLBase!)) {
              path = path.replace(environment.deepLinkURLBase!, '');
              if (path.startsWith('/'))
                path = path.replace(/^./, "");
            }
            await this.router.navigateByUrl(path);
            this.events.appDeeplinkEvent.emit(path);
          } catch (e) {
            console.error(e);
          }
        }
      });
    });

    App.addListener('appStateChange', ({ isActive }) => {
      this._systemLogService.appStateChanged(isActive);
    });

    App.addListener('resume', () => {
      this.zone.run(async () => {
        await this.servicesStartupService.resumeApp();
        this.initialized = true;
        this.events.appResumed.emit();
      });

    });

    App.addListener('pause', () => {
      this.events.appPaused.emit();
    });
  }


  ngAfterViewInit() {
    this._loadingService.initialize(this.vcr);
  }

  async ngOnInit(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      OneSignal.initialize(environment.oneSignalAppId);
    }
    if (!Capacitor.isNativePlatform()) {
      this.renderer.addClass(document.body, 'web-platform');
    }

    await this.servicesStartupService.initServices();


  }

}
