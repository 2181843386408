import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { from, lastValueFrom, Observable, of, throwError, TimeoutError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { NavController } from '@ionic/angular';
import { LoadingService } from '../loading-service/loading.service';
import { ILocalAppEnvironment } from '../environment-config-service/environment-config.service';
import { AppEnvironmentAppEnvironmentService } from '../../api/proxy/app-environment/app-environment-services';
import { Capacitor } from '@capacitor/core';
import { App, AppInfo } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { Network } from '@capacitor/network';
import { AuthenticationLocalAccountService } from 'src/app/api/proxy/auth/authentication-services';
import { OrganizationConsumerService } from 'src/app/api/proxy/organization/organization-services';
import { EventService } from '../events/event.service';
import { UserData } from './user-data.model';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  appInfo?: AppInfo | null = null;
  appInfoLoaded = false;
  environment: ILocalAppEnvironment | null = null;
  currentUser: UserData | null = null;
  constructor(
    private _loading: LoadingService,
    public navCtrl: NavController,
    private _EventService: EventService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._EventService.environmentLoaded.subscribe(async (env: ILocalAppEnvironment) => {
      this.environment = env;
    });

    this._EventService.userUpdated.subscribe(async (usr: UserData | null) => {
      this.currentUser = usr;
    });

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var urls = this.getIgnoredEndpoints();
    for (var url of urls) {
      if (request.url.includes(url)) {
        return next.handle(request);
      }
    }
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<unknown>> {
    const status = await Network.getStatus();

    if (!status.connected) {

      // Return an Observable that emits an HttpErrorResponse wrapped in throwError
      return lastValueFrom(
        next.handle(request).pipe(
          catchError(() => {
            return throwError(() => new HttpErrorResponse({
              error: 'No network connection',
              status: 0, // Custom status code for offline
              statusText: 'Offline',
              url: request.url
            }));
          })
        )
      );
    }


    const ignoreEndpoints = await this.getAnonymousEndpoints();
    if (ignoreEndpoints) {
      for (const endpoint of ignoreEndpoints) {
        if (request.url.includes(endpoint)) {
          return this.handleRequest(request, next);
        }
      }
    }

    //we cannot use the ensure network config as this might casue the app to stack overflow.
    const environmentConfig = this.environment;

    request = request.clone({
      headers: request.headers
        .set('user-language', this.document.documentElement.lang)
        .set('ngrok-skip-browser-warning', 'true')
    });

    if (environmentConfig) {
      if (!this.appInfo) {
        this.appInfo = Capacitor.getPlatform() !== 'web' ? await App.getInfo() : {
          name: window.location.hostname,
          id: environment.webAppId,
          version: environment.version,
          build: environment.build.toString(),
        };
      }

      if (this.appInfo && environmentConfig.countryCode) {
        request = request.clone({
          headers: request.headers
            .set('ion-app-deviceType', Capacitor.getPlatform())
            .set('ion-app-name', this.appInfo.name)
            .set('ion-app-id', this.appInfo.id)
            .set('ion-app-version', this.appInfo.version)
            .set('ion-app-build', this.appInfo.build)
            .set('ion-app-countryCode', environmentConfig.countryCode!)
        });
      }
    }

    const usr = this.currentUser;
    if (usr) {
      const personVal = btoa(`${usr.sub!}|${usr.tokenClaims.personId!}`);
      request = request.clone({
        headers: request.headers.set('correlation', personVal)
      });
    }

    if (environmentConfig && environmentConfig.faceScanConfig && environmentConfig.faceScanConfig.faceScanBase && request.url.includes(environmentConfig.faceScanConfig.faceScanBase)) {
      request = request.clone({
        headers: request.headers.set('healthcheck-systemIdentifier-id', environmentConfig.faceScanConfig.systemIdentifierId!)
      });
    }

    if (this.currentUser) {
      const token = this.currentUser?.access_token;
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
      }
    }

    return this.handleRequest(request, next);
  }

  async handleRequest(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    // Show the loading indicator
    await this._loading.setLoading(true, request.url);

    return lastValueFrom(
      next.handle(request).pipe(
        map(evt => {
          // Check if the event is an HttpResponse (successful response)
          if (evt instanceof HttpResponse) {
          }
          return evt;
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            //token propably expired.
            if (this.currentUser) {
              setTimeout(async () => {
                window.location.href = '/'; // Redirects to the root URL
              }, 3000);
            }

          }
          return throwError(() => error);
        }),
        // Ensure that the loading indicator is closed regardless of success or failure
        finalize(() => {
          this._loading.setLoading(false, request.url);
        })
      )
    );
  }


  private async getAnonymousEndpoints(): Promise<string[] | null> {
    var result = [
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountRegisterUserPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountLoginUserPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountRefreshTokenPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountRequestOtpPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountVerifyOtpAsyncPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountForgotPasswordRequestPostPath),
      this.extractUrlWithNoParameters(AuthenticationLocalAccountService.LocalAccountResetForgotPasswordPostPath),
      this.extractUrlWithNoParameters(OrganizationConsumerService.ConsumerGetByTokenTokenGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentGetVivaScoreAsyncPostPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentCountriesGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentSettingsPostPath)

    ];
    return result;
  }

  private getIgnoredEndpoints(): string[] {

    const result: string[] = ['/AppLogMessage',
      '/i18n/',
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentSettingsPostPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentCountriesGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentEnvironmentSetupEnvironmentNameCountryCodeDeviceTypeNameAppIdVersionBuildProductionGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentCountriesForUserDeviceTypeAppIdProductionGetPath),
      this.extractUrlWithNoParameters(AppEnvironmentAppEnvironmentService.AppEnvironmentVivaScorePingPostPath),
      'https://api.onesignal.com/'];
    return result;
  }


  extractUrlWithNoParameters(url: string): string {
    const index = url.indexOf('{');
    const result = index === -1 ? url : url.substring(0, index);
    return result;
  }

}
