import { Injectable } from '@angular/core';
import { StorageKey } from '../storage/models/storage-key.enum';
import { firstValueFrom } from 'rxjs';
import { HealthScore } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { SecureStorageService } from '../storage/secure-storage.service';
import { PromptService } from '../promtp-service/prompt.service';
import { VitalScoreProfileService } from 'src/app/api/proxy/vitalscore/vitalscore-services';
import { EventService } from '../events/event.service';
import { LoadingService } from '../loading-service/loading.service';
import { isAfter } from 'date-fns';
import { Network } from '@capacitor/network';


@Injectable({
  providedIn: 'root',
})
export class HealthScoreService {

  constructor(private _StorageService: SecureStorageService,
    protected _PromptService: PromptService,
    protected _vitalScoreProfileService: VitalScoreProfileService,
    private _loadingService: LoadingService,
    private events: EventService,
  ) {
    _loadingService.registerSilentUrl(VitalScoreProfileService.ProfileHealthScoreRefreshGetPath);

  }
  private blocking = false;
  private _nextRefresh: Date | null = null;
  public async GetLastScore(forced: boolean, source:string): Promise<void> {

    var tempdt = await this._StorageService.get(StorageKey.NextHealthScoreRefresh);
    if(tempdt){
      this._nextRefresh = new Date(tempdt);
    }



    var myHealthScore = await this._StorageService.get(StorageKey.MyHealthScore);
    var model: HealthScore | null = null;
    if (myHealthScore) {
      try {
        model = JSON.parse(myHealthScore);

      } catch {
        model = null;
      }
    }


    if (model) {
      this._loadingService.registerSilentUrl(VitalScoreProfileService.ProfileHealthScoreRefreshGetPath);
      this.events.healthScoreUpdated.emit(model);
    } else {
      this._loadingService.removeSilentUrl(VitalScoreProfileService.ProfileHealthScoreRefreshGetPath);
    }



    if (!forced && model) {
      var dt = new Date();

      if (this._nextRefresh && !isAfter(new Date(), this._nextRefresh)) {
        return;
      }

      const status = await Network.getStatus();
      if (status.connected === false) {
        return;
      }
    }

    if (this.blocking)
      return;
    this.blocking = true;
    try {
      var promise = firstValueFrom(this._vitalScoreProfileService.profileHealthScoreGet());
      await promise.then(async (result) => {
        if (result) {
          this._nextRefresh = new Date(new Date().getTime() + 60 * 5 * 1000);//5 minutes
          await this._StorageService.set(StorageKey.NextHealthScoreRefresh, this._nextRefresh.toISOString());
          await this._StorageService.set(StorageKey.MyHealthScore, JSON.stringify(result));
          this.events.healthScoreUpdated.emit(result);
        }
        return result;
      }).catch(async (err) => {
        return model;
      });

    } finally {
      this.blocking = false;
    }
  }
  public async initService(source:string): Promise<void> {
    await this.GetLastScore(false,'healthScoreService.initService - ' + source);
  }
}
