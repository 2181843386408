/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FaceScanStrictHttpResponse } from '../../face-scan-strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface SessionTestNotifyCompleteTestSessionIdGet$Params {
  sessionId: string;
}

export function sessionTestNotifyCompleteTestSessionIdGet(http: HttpClient, rootUrl: string, params: SessionTestNotifyCompleteTestSessionIdGet$Params, context?: HttpContext): Observable<FaceScanStrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, sessionTestNotifyCompleteTestSessionIdGet.PATH, 'get');
  if (params) {
    rb.path('sessionId', params.sessionId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as FaceScanStrictHttpResponse<void>;
    })
  );
}

sessionTestNotifyCompleteTestSessionIdGet.PATH = '/Session/TestNotifyCompleteTest/{sessionId}';
