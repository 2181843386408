/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationBaseService } from '../authentication-base-service';
import { AuthenticationApiConfiguration } from '../authentication-api-configuration';
import { AuthenticationStrictHttpResponse } from '../authentication-strict-http-response';

import { AccessTokenModel } from '../models/access-token-model';
import { localAccountChangePasswordPost } from '../fn/local-account/local-account-change-password-post';
import { LocalAccountChangePasswordPost$Params } from '../fn/local-account/local-account-change-password-post';
import { localAccountDeleteAccountDelete } from '../fn/local-account/local-account-delete-account-delete';
import { LocalAccountDeleteAccountDelete$Params } from '../fn/local-account/local-account-delete-account-delete';
import { localAccountForgotPasswordRequestPost } from '../fn/local-account/local-account-forgot-password-request-post';
import { LocalAccountForgotPasswordRequestPost$Params } from '../fn/local-account/local-account-forgot-password-request-post';
import { localAccountLoginUserPost } from '../fn/local-account/local-account-login-user-post';
import { LocalAccountLoginUserPost$Params } from '../fn/local-account/local-account-login-user-post';
import { localAccountRefreshTokenPost } from '../fn/local-account/local-account-refresh-token-post';
import { LocalAccountRefreshTokenPost$Params } from '../fn/local-account/local-account-refresh-token-post';
import { localAccountRegisterUserPost } from '../fn/local-account/local-account-register-user-post';
import { LocalAccountRegisterUserPost$Params } from '../fn/local-account/local-account-register-user-post';
import { localAccountRequestOtpPost } from '../fn/local-account/local-account-request-otp-post';
import { LocalAccountRequestOtpPost$Params } from '../fn/local-account/local-account-request-otp-post';
import { localAccountResetForgotPasswordPost } from '../fn/local-account/local-account-reset-forgot-password-post';
import { LocalAccountResetForgotPasswordPost$Params } from '../fn/local-account/local-account-reset-forgot-password-post';
import { localAccountUpdateProfilePost } from '../fn/local-account/local-account-update-profile-post';
import { LocalAccountUpdateProfilePost$Params } from '../fn/local-account/local-account-update-profile-post';
import { localAccountUserCanLoginGet } from '../fn/local-account/local-account-user-can-login-get';
import { LocalAccountUserCanLoginGet$Params } from '../fn/local-account/local-account-user-can-login-get';
import { localAccountVerifyOtpAsyncPost } from '../fn/local-account/local-account-verify-otp-async-post';
import { LocalAccountVerifyOtpAsyncPost$Params } from '../fn/local-account/local-account-verify-otp-async-post';
import { RefreshTokenResponseModel } from '../models/refresh-token-response-model';
import { RequestOtpResponseModel } from '../models/request-otp-response-model';

@Injectable({ providedIn: 'root' })
export class AuthenticationLocalAccountService extends AuthenticationBaseService {
  constructor(config: AuthenticationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `localAccountUserCanLoginGet()` */
  static readonly LocalAccountUserCanLoginGetPath = '/LocalAccount/UserCanLogin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountUserCanLoginGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  localAccountUserCanLoginGet$Response(params?: LocalAccountUserCanLoginGet$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<boolean>> {
    return localAccountUserCanLoginGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountUserCanLoginGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  localAccountUserCanLoginGet(params?: LocalAccountUserCanLoginGet$Params, context?: HttpContext): Observable<boolean> {
    return this.localAccountUserCanLoginGet$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `localAccountRegisterUserPost()` */
  static readonly LocalAccountRegisterUserPostPath = '/LocalAccount/RegisterUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountRegisterUserPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountRegisterUserPost$Response(params?: LocalAccountRegisterUserPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<AccessTokenModel>> {
    return localAccountRegisterUserPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountRegisterUserPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountRegisterUserPost(params?: LocalAccountRegisterUserPost$Params, context?: HttpContext): Observable<AccessTokenModel> {
    return this.localAccountRegisterUserPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<AccessTokenModel>): AccessTokenModel => r.body)
    );
  }

  /** Path part for operation `localAccountLoginUserPost()` */
  static readonly LocalAccountLoginUserPostPath = '/LocalAccount/LoginUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountLoginUserPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountLoginUserPost$Response(params?: LocalAccountLoginUserPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<AccessTokenModel>> {
    return localAccountLoginUserPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountLoginUserPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountLoginUserPost(params?: LocalAccountLoginUserPost$Params, context?: HttpContext): Observable<AccessTokenModel> {
    return this.localAccountLoginUserPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<AccessTokenModel>): AccessTokenModel => r.body)
    );
  }

  /** Path part for operation `localAccountRequestOtpPost()` */
  static readonly LocalAccountRequestOtpPostPath = '/LocalAccount/RequestOTP';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountRequestOtpPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountRequestOtpPost$Response(params?: LocalAccountRequestOtpPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<RequestOtpResponseModel>> {
    return localAccountRequestOtpPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountRequestOtpPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountRequestOtpPost(params?: LocalAccountRequestOtpPost$Params, context?: HttpContext): Observable<RequestOtpResponseModel> {
    return this.localAccountRequestOtpPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<RequestOtpResponseModel>): RequestOtpResponseModel => r.body)
    );
  }

  /** Path part for operation `localAccountVerifyOtpAsyncPost()` */
  static readonly LocalAccountVerifyOtpAsyncPostPath = '/LocalAccount/VerifyOTPAsync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountVerifyOtpAsyncPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountVerifyOtpAsyncPost$Response(params?: LocalAccountVerifyOtpAsyncPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<boolean>> {
    return localAccountVerifyOtpAsyncPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountVerifyOtpAsyncPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountVerifyOtpAsyncPost(params?: LocalAccountVerifyOtpAsyncPost$Params, context?: HttpContext): Observable<boolean> {
    return this.localAccountVerifyOtpAsyncPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `localAccountRefreshTokenPost()` */
  static readonly LocalAccountRefreshTokenPostPath = '/LocalAccount/RefreshToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountRefreshTokenPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountRefreshTokenPost$Response(params?: LocalAccountRefreshTokenPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<RefreshTokenResponseModel>> {
    return localAccountRefreshTokenPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountRefreshTokenPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountRefreshTokenPost(params?: LocalAccountRefreshTokenPost$Params, context?: HttpContext): Observable<RefreshTokenResponseModel> {
    return this.localAccountRefreshTokenPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<RefreshTokenResponseModel>): RefreshTokenResponseModel => r.body)
    );
  }

  /** Path part for operation `localAccountChangePasswordPost()` */
  static readonly LocalAccountChangePasswordPostPath = '/LocalAccount/ChangePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountChangePasswordPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountChangePasswordPost$Response(params?: LocalAccountChangePasswordPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<void>> {
    return localAccountChangePasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountChangePasswordPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountChangePasswordPost(params?: LocalAccountChangePasswordPost$Params, context?: HttpContext): Observable<void> {
    return this.localAccountChangePasswordPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `localAccountUpdateProfilePost()` */
  static readonly LocalAccountUpdateProfilePostPath = '/LocalAccount/UpdateProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountUpdateProfilePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountUpdateProfilePost$Response(params?: LocalAccountUpdateProfilePost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<void>> {
    return localAccountUpdateProfilePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountUpdateProfilePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountUpdateProfilePost(params?: LocalAccountUpdateProfilePost$Params, context?: HttpContext): Observable<void> {
    return this.localAccountUpdateProfilePost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `localAccountDeleteAccountDelete()` */
  static readonly LocalAccountDeleteAccountDeletePath = '/LocalAccount/DeleteAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountDeleteAccountDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  localAccountDeleteAccountDelete$Response(params?: LocalAccountDeleteAccountDelete$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<void>> {
    return localAccountDeleteAccountDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountDeleteAccountDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  localAccountDeleteAccountDelete(params?: LocalAccountDeleteAccountDelete$Params, context?: HttpContext): Observable<void> {
    return this.localAccountDeleteAccountDelete$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `localAccountForgotPasswordRequestPost()` */
  static readonly LocalAccountForgotPasswordRequestPostPath = '/LocalAccount/ForgotPasswordRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountForgotPasswordRequestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountForgotPasswordRequestPost$Response(params?: LocalAccountForgotPasswordRequestPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<void>> {
    return localAccountForgotPasswordRequestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountForgotPasswordRequestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountForgotPasswordRequestPost(params?: LocalAccountForgotPasswordRequestPost$Params, context?: HttpContext): Observable<void> {
    return this.localAccountForgotPasswordRequestPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `localAccountResetForgotPasswordPost()` */
  static readonly LocalAccountResetForgotPasswordPostPath = '/LocalAccount/ResetForgotPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `localAccountResetForgotPasswordPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountResetForgotPasswordPost$Response(params?: LocalAccountResetForgotPasswordPost$Params, context?: HttpContext): Observable<AuthenticationStrictHttpResponse<void>> {
    return localAccountResetForgotPasswordPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `localAccountResetForgotPasswordPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  localAccountResetForgotPasswordPost(params?: LocalAccountResetForgotPasswordPost$Params, context?: HttpContext): Observable<void> {
    return this.localAccountResetForgotPasswordPost$Response(params, context).pipe(
      map((r: AuthenticationStrictHttpResponse<void>): void => r.body)
    );
  }

}
