import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { EventService } from '../events/event.service';
import { NavController } from '@ionic/angular';
import { UserInfoService } from './user-info-service';
import { Semaphore } from 'src/app/utilities/semaphore/semaphore.utility';
import { EnvironmentConfigService } from '../environment-config-service/environment-config.service';
import { AuthenticationLocalAccountService } from 'src/app/api/proxy/auth/authentication-services';
import { AccessTokenModel, ChangePasswordRequestModel, ForgotPasswordRequestModel, LoginRequestModel, RefreshTokenRequestModel, RefreshTokenResponseModel, RequestOtpModel, RequestOtpResponseModel, ResetPasswordRequestModel, VerifyOtpRequestModel } from 'src/app/api/proxy/auth/authentication-models';
import { firstValueFrom, of } from 'rxjs';
import { addSeconds, differenceInSeconds, formatISO, isAfter, parseISO } from 'date-fns';
import { BiometricLoginDetail, UserData } from './user-data.model';
import { Capacitor } from '@capacitor/core';
import { OneSignalService } from '../one-signal-service/one-signal.service';
import { Network } from '@capacitor/network';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { LoadingService } from '../loading-service/loading.service';
import { localAccountRefreshTokenPost } from 'src/app/api/proxy/auth/fn/local-account/local-account-refresh-token-post';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private events: EventService,
    private secureStorageService: SecureStorageService,
    public navCtrl: NavController,
    private _userInfoService: UserInfoService,
    private router: Router,
    private _EnvironmentConfigService: EnvironmentConfigService,
    private _localAccountService: AuthenticationLocalAccountService,
    private _systemLogService: SystemLogService,
    private _loadingService: LoadingService
  ) {
    this.events.appPaused.subscribe(() => {
      this.clearRefresh();
    });

    this.events.appResumed.subscribe(() => {
      this.setupRefreshTimer();
    });
  }

  public async tokenExpired(): Promise<boolean> {
    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo && userInfo.tokenExpiry && userInfo.access_token) {
      const dt = parseISO(userInfo.tokenExpiry);
      const compareDate = new Date();
      if (isAfter(dt, compareDate)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  public async token(): Promise<string | null> {
    var userInfo = await this._userInfoService.getUserInfo();
    if (userInfo && userInfo.access_token) {
      return userInfo.access_token;
    }
    return null;
  }

  public async saveBiometricLogin(countryCode: string, email: string, password: string) {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    var biometricLogin: BiometricLoginDetail = {
      username: email,
      password: password,
      countrycode: countryCode
    };
    await this.secureStorageService.set(SecureStorageKey.BiometricLogin, JSON.stringify(biometricLogin));
  }

  public async getBiometricLogin(): Promise<BiometricLoginDetail | null> {
    if (Capacitor.getPlatform() === 'web') {
      return null;
    }

    var userData = await this.secureStorageService.get(
      SecureStorageKey.BiometricLogin
    );

    if (!userData || userData.length === 0 || userData === '') {
      return null;
    }
    var userInfo = JSON.parse(userData) as BiometricLoginDetail;
    if (!userInfo) {
      return null;
    }

    return userInfo;
  }

  public async clearBiometricLogin(): Promise<void> {
    var bioData = await this.getBiometricLogin();
    if (bioData) {
      await this.secureStorageService.set(SecureStorageKey.BiometricLogin, '');
      await this.secureStorageService.remove(SecureStorageKey.BiometricLogin);
    }
  }

  public async login(emailAddress: string, pasw: string): Promise<boolean> {
    const status = await Network.getStatus();
    if (status.connected === false) {
      return false;
    }

    try {
      const envConfig = await this._EnvironmentConfigService.EnsureNetworkConfiguration("auth-service-login");
      if (!envConfig) {
        return false;
      }

      const loginRequest: LoginRequestModel = {
        applicationId: envConfig.authenticateOptions!.appId!,
        applicationScope: envConfig.authenticateOptions!.scope!,
        emailAddress: emailAddress,
        password: pasw,
      };

      const response = await firstValueFrom(
        this._localAccountService.localAccountLoginUserPost({ body: loginRequest })
      );

      if (response && response.access_token && response.access_token.length > 0 && !response.error) {
        const tokenResponse = response as AccessTokenModel;

        if (!tokenResponse.expires_in) {
          tokenResponse.expires_in = (55 * 60).toString();
        }

        const expires_in = parseInt(tokenResponse.expires_in) * 0.75;
        const dt = addSeconds(new Date(), expires_in);
        const expiry = formatISO(dt);

        const token = this.parseJwt(tokenResponse.access_token);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          time: formatISO(new Date()),
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: expiry,
          waitTime: expires_in,
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions
        };

        var oldUser = await this._userInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await this.secureStorageService.clear();
        }
        await this._userInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        return true; // Login successful
      } else {
        return false; // Login failed
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      return false; // Unexpected error
    }
  }

  public async forgotPasswordRequest(email: string): Promise<boolean> {
    try {
      var model: ForgotPasswordRequestModel = { emailAddress: email };
      const response = await firstValueFrom(
        this._localAccountService.localAccountForgotPasswordRequestPost({ body: model })
      );
      return true;
    } catch (error) {
      console.error('Unexpected error:', error);
      return false;
    }
  }

  public async resetForgotPassword(email: string, password: string, otp: number): Promise<boolean> {
    try {
      var model: ResetPasswordRequestModel = { emailAddress: email, newPassword: password, otp: otp };
      const response = await firstValueFrom(this._localAccountService.localAccountResetForgotPasswordPost({ body: model }));
      this.clearBiometricLogin();
      return true;
    } catch (error) {
      console.error('Unexpected error:', error);
      return false;
    }
  }

  public async changePassword(password: string) {
    try {
      var model: ChangePasswordRequestModel = { newPassword: password };
      const response = await firstValueFrom(
        this._localAccountService.localAccountChangePasswordPost({ body: model })
      );

      var bioPassword = await this.getBiometricLogin();
      if (bioPassword) {
        await this.saveBiometricLogin(bioPassword.countrycode, bioPassword.username, password);
      }
      return true;
    } catch (error) {
      console.error('Unexpected error:', error);
      return false;
    }
  }

  public async registerProfile(tokenResponse: AccessTokenModel): Promise<boolean> {
    try {

      if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
        if (!tokenResponse.expires_in) {
          tokenResponse.expires_in = (55 * 60).toString();
        }

        const expires_in = parseInt(tokenResponse.expires_in) * 0.75;
        const dt = addSeconds(new Date(), expires_in);
        const expiry = formatISO(dt);

        const token = this.parseJwt(tokenResponse.access_token);
        const userInfo: UserData = {
          sub: token.oid,
          given_name: token.given_name,
          family_name: token.family_name,
          name: token.name,
          time: formatISO(new Date()),
          access_token: tokenResponse.access_token!,
          refresh_token: tokenResponse.refresh_token!,
          tokenExpiry: expiry,
          waitTime: expires_in,
          tokenClaims: token,
          email: token.userEmailAddress!,
          language: token.language,
          systemFunctions: token.systemFunctions
        };

        var oldUser = await this._userInfoService.getUserInfo();
        if (oldUser && oldUser.sub !== userInfo.sub) {
          await this.secureStorageService.clear();
        }
        await this._userInfoService.setUserInfo(userInfo);
        await this.setUserLoggedInAsync();
        return true; // Login successful
      } else {
        throw new Error("Unexpected error occurred");
      }
    } catch (error) {
      this._systemLogService.logError(error);
      return false;
    }
  }

  public async requestOTP(model: RequestOtpModel): Promise<RequestOtpResponseModel | null> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountRequestOtpPost({ body: model })
      );
      return response;
    } catch (error) {
      console.error('Unexpected error:', error);
      return null;
    }
  }

  public async verifyOTP(model: VerifyOtpRequestModel): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountVerifyOtpAsyncPost({ body: model })
      );
      return response == true;
    } catch (error) {
      console.error('Unexpected error:', error);
      return false;
    }
  }

  private parseJwt(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  private lock = new Semaphore(1);

  public async refreshToken(timerRefresh: boolean): Promise<boolean> {
    var result = false;
    await this.lock.callFunction(async (_: any) => {
      result = await this.refresh(timerRefresh);
    }, []);
    return result;
  }

  public async initAuthService(): Promise<boolean> {
    var userData = await this._userInfoService.getUserInfo();
    if (userData) {
      var expired = await this.tokenExpired();
      if (expired) {
        await this.refreshToken(false);
      }
      expired = await this.tokenExpired();
      if (!expired) {
        await this.setUserLoggedInAsync();
      }
    } else {
      const nav = this.router.getCurrentNavigation();
      const extractedUrl = nav?.extractedUrl;
      var returnURL: string | null | undefined = null;
      if (nav && nav.extractedUrl) {
        returnURL = extractedUrl?.toString();
      }
    }
    return true;
  }

  public async logout(): Promise<void> {
    await this.clearUserData(true);

    setTimeout(() => {
      window.location.href = '/'; // Redirects to the root URL
    }, 1000);
  }

  public async clearUserData(clearBiologin: boolean = true): Promise<void> {
    await this._userInfoService.setUserInfo(null);
    this.clearRefresh(); //terminate timers 

    var region = await this.secureStorageService.get(SecureStorageKey.CurrentRegion);
    var bioLogin = await this.secureStorageService.get(SecureStorageKey.BiometricLogin);
    this.secureStorageService.clear();
    if (bioLogin && !clearBiologin) {
      await this.secureStorageService.set(SecureStorageKey.BiometricLogin, bioLogin);
    }
    if (region) {
      await this.secureStorageService.set(SecureStorageKey.CurrentRegion, region);
      await this._EnvironmentConfigService.EnsureNetworkConfiguration("auth-service-clearUserData");
    }
  }


  private async refresh(timerRefresh: boolean): Promise<boolean> {
    const status = await Network.getStatus();
  
    if (status.connected === false) {
      await this.setupRefreshTimer();
      return false;
    }

    try {
      const userInfo = await this._userInfoService.getUserInfo();
      if (userInfo) {
        var expired = await this.tokenExpired();
        if (!timerRefresh && !expired) {
          return true;
        }
        if (!userInfo.refresh_token) {
          return false;
        }
        var env = await this._EnvironmentConfigService.EnsureNetworkConfiguration("auth-service-refreshToken");
        var refreshOptions: RefreshTokenRequestModel = {
          applicationId: env!.authenticateOptions!.appId!,
          refreshToken: userInfo.refresh_token!
        }

        this._loadingService.registerSilentUrl(AuthenticationLocalAccountService.LocalAccountRefreshTokenPostPath);

        const response = await firstValueFrom(
          this._localAccountService.localAccountRefreshTokenPost({ body: refreshOptions })
        );
        if (response && response.access_token && response.access_token.length > 0 && !response.error) {
          var tokenResponse = response as RefreshTokenResponseModel;
          if (tokenResponse && tokenResponse.access_token && tokenResponse.access_token.length > 0 && !tokenResponse.error) {
            if (!tokenResponse.expires_in) {
              tokenResponse.expires_in = (55 * 60);
            }

            var expires_in = tokenResponse.expires_in * 0.75;
            const dt = addSeconds(new Date(), expires_in);
            const expiry = formatISO(dt);

            const token = this.parseJwt(tokenResponse.access_token);
            var tokenUser: UserData = {
              sub: token.sub,
              given_name: token.given_name,
              family_name: token.family_name,
              name: token.name,
              time: formatISO(new Date()),
              access_token: tokenResponse.access_token!,
              refresh_token: tokenResponse.refresh_token!,
              tokenExpiry: expiry,
              waitTime: expires_in,
              tokenClaims: token,
              email: token.userEmailAddress!,
              language: token.language,
              systemFunctions: token.systemFunctions
            };
            await this._userInfoService.setUserInfo(tokenUser);

            await this.setUserLoggedInAsync();
            return true; // Login successful
          }
        } else {
          await this.setupRefreshTimer();
          return false; // Login failed
        }
      }
      await this.setupRefreshTimer();
      return false;
    } catch (error) {
      console.error('Unexpected error:', error);
      await this.setupRefreshTimer();
      return false; // Unexpected error
    }
  }


  private async setUserLoggedInAsync(): Promise<void> {

    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo) {
      await this.setupRefreshTimer();
    }
  }

  private refreshInterval: any;
  private clearRefresh() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  private nextReresh: Date | null = null;
  private async singleRefreshSetup() {
    // Check if the next refresh is scheduled and still in the future
    if (this.refreshInterval && this.nextReresh && isAfter(this.nextReresh, new Date())) {
      return;
    }
  
    let waitTimeSeconds = 10;
    const userInfo = await this._userInfoService.getUserInfo();
    if (!userInfo) {
      return;
    }
  
    // Get the current time in UTC
    const utcNow = new Date();
    let end = utcNow;
  
    // Parse the token expiry time as a UTC date
    if (userInfo.tokenExpiry) {
      end = parseISO(userInfo.tokenExpiry);
    }
  
    // Calculate the difference in seconds between the token expiry and the current time
    const seconds = differenceInSeconds(end, utcNow);
  
    // Adjust the wait time based on the time remaining before the token expires
    if (seconds > 1) {
      waitTimeSeconds = seconds * 0.75;
    }

    
  
    // Schedule the next refresh
    this.nextReresh = addSeconds(utcNow, waitTimeSeconds);
  
    // Clear any existing refresh interval and set a new one
    this.clearRefresh();
  
    this.refreshInterval = setInterval(async () => {
      await this.refreshToken(true);
    }, waitTimeSeconds * 1000);
  }
  

  private refreshLock = new Semaphore(1);

  private async setupRefreshTimer() {
    var result = false;
    await this.refreshLock.callFunction(async (_: any) => {
      await this.singleRefreshSetup();
    }, []);
    return result;
   
  }

  public async DeleteProfile(): Promise<boolean> {
    try {
      const response = await firstValueFrom(
        this._localAccountService.localAccountDeleteAccountDelete()
      );

      //logout and clear local storage
      await this.logout();
      return true;
    } catch (error) {
      console.error('Unexpected error:', error);
      return false;
    }
  }

}
