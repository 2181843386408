import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { FaceScanModule } from './api/proxy/facescan/face-scan.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalHttpInterceptorService } from './services/auth-service/global-http-interceptor-service ';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { WearablesModule } from './api/proxy/wearables/wearables.module';
import { VitalScoreModule } from './api/proxy/vitalscore/vital-score.module';
import { MainMenuModule } from './components/main-menu/main-menu.module';
import { AppEnvironmentModule } from './api/proxy/app-environment/app-environment.module';
import { OrganizationModule } from './api/proxy/organization/organization.module';
import { VirtualHealthLibraryModule } from './api/proxy/virtualHealthLibrary/virtual-health-library.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        IonicModule.forRoot({
            rippleEffect: false,
            mode: 'md'
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MainMenuModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AppEnvironmentModule.forRoot({ rootUrl: environment.appEnvironmentBaseUrl }),
        FaceScanModule.forRoot({ rootUrl: '' }),
        WearablesModule.forRoot({ rootUrl: '' }),
        VitalScoreModule.forRoot({ rootUrl: '' }),
        VirtualHealthLibraryModule.forRoot({ rootUrl: '' }),
        OrganizationModule.forRoot({ rootUrl: '' }),
        SharedModule], providers: [
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule {
    constructor() {
     
    }
}
