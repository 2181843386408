import { Injectable } from '@angular/core';
import { AppAccessType, AppLogModel, PageViewLogModel, TimeSpentLogModel } from 'src/app/api/proxy/vitalscore/vitalscore-models';
import { UserInfoService } from '../auth-service/user-info-service';
import { Capacitor } from '@capacitor/core';
import { AuthService } from '../auth-service/auth.service';
import { LoadingService } from '../loading-service/loading.service';
import { EventService } from '../events/event.service';
import { VitalScoreProfileService } from 'src/app/api/proxy/vitalscore/vitalscore-services';
import { environment } from 'src/environments/environment';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class SystemLogService {

  private userId: string = '';
  private messgeNumber: number = 0;

  private lastAcive: Date | null = null;
  private logEnabled: boolean = false;

  constructor(private _logService: VitalScoreProfileService,
    private _userInfoService: UserInfoService,
    private _authService: AuthService,
    private _loadingService: LoadingService,
    private _EventService: EventService) {
    this._loadingService.registerSilentUrl(VitalScoreProfileService.ProfileAppLogMessagePostPath);
    this._loadingService.registerSilentUrl(VitalScoreProfileService.ProfileLogTimeSpentPostPath);
    this._loadingService.registerSilentUrl(VitalScoreProfileService.ProfileLogPageAccessPostPath);
  }

  enableLogging() {
    this.logEnabled = true;
  }

  async logMessage(message: string | null, error: boolean = false): Promise<void> {
    if (!this.logEnabled) {
      return;
    }
    const userInfo = await this._userInfoService.getUserInfo();
    this.messgeNumber++;
    if (message == null || message.length <= 0)
      return;

    if (this.userId == '') {
      if (userInfo && userInfo.email) {
        this.userId = userInfo.email;
      }
    }

    var msg: AppLogModel = {
      logMessage: message,
      userId: this.messgeNumber.toString() + ":->" + Capacitor.getPlatform() + '->' + this.userId
    }

    var envModel = {
      platForm: Capacitor.getPlatform(),
      version: environment.version,
      build: environment.build,
      environment: environment.environmentName,
      userId: userInfo?.email
    }

    msg.userObject = JSON.stringify(envModel);
    const status = await Network.getStatus();
    if (status.connected === false) {
      return;
    }
    try {
      await this._logService.profileAppLogMessagePost({ body: msg }).subscribe(() => {
      });
    } catch (error) {

      console.error(error);
    }

  }


  async logError(errorObject: unknown, statusText: string = 'error', title: string = 'error'): Promise<void> {
    let errorMessage = '';

    if (errorObject instanceof Error) {
      // If errorObject is an instance of Error, extract message and stack
      errorMessage = `${title}:${statusText}:-> Message: ${errorObject.message}, Stack: ${errorObject.stack}`;
    } else {
      // Fallback if errorObject is not an Error instance, try JSON.stringify
      errorMessage = `${title}:${statusText}:-> ${JSON.stringify(errorObject)}`;
    }

    await this.logMessage(errorMessage, true);
  }

  async appStateChanged(active: boolean): Promise<void> {

    if (!active && this.lastAcive) {
      var activeTime = new Date().getTime() - this.lastAcive.getTime();

      var expired = this._authService.tokenExpired();
      if (!expired) {
        try {
          var msg: TimeSpentLogModel = {
            appStarted: this.lastAcive.toISOString(),
            timeSpent: activeTime
          };
          const status = await Network.getStatus();
          if (status.connected === false) {
            return;
          }
          await this._logService.profileLogTimeSpentPost({ body: msg });
        } catch (error) {
          console.error(error);
        }
      }
    }
    this.lastAcive = new Date();
  }

  public async logPageAccessed(page: AppAccessType): Promise<void> {

    //fire a resize event to notify all charts to re-render. this is a fix for the apex chart display bug when switching between pages.
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    });

    this._EventService.pageFocused.emit(page);

    var msg: PageViewLogModel = {
      accessType: page
    };
    const status = await Network.getStatus();
    if (status.connected === false) {
      return;
    }
    this._logService.profileLogPageAccessPost({ body: msg });
  }
}


