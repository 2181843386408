/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WearablesBaseService } from '../wearables-base-service';
import { WearablesApiConfiguration } from '../wearables-api-configuration';
import { WearablesStrictHttpResponse } from '../wearables-strict-http-response';

import { reportWeeklyStatisticsPost } from '../fn/report/report-weekly-statistics-post';
import { ReportWeeklyStatisticsPost$Params } from '../fn/report/report-weekly-statistics-post';
import { WeeklyStatisticsModel } from '../models/weekly-statistics-model';

@Injectable({ providedIn: 'root' })
export class WearablesReportService extends WearablesBaseService {
  constructor(config: WearablesApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reportWeeklyStatisticsPost()` */
  static readonly ReportWeeklyStatisticsPostPath = '/Report/WeeklyStatistics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportWeeklyStatisticsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportWeeklyStatisticsPost$Response(params?: ReportWeeklyStatisticsPost$Params, context?: HttpContext): Observable<WearablesStrictHttpResponse<WeeklyStatisticsModel>> {
    return reportWeeklyStatisticsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportWeeklyStatisticsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reportWeeklyStatisticsPost(params?: ReportWeeklyStatisticsPost$Params, context?: HttpContext): Observable<WeeklyStatisticsModel> {
    return this.reportWeeklyStatisticsPost$Response(params, context).pipe(
      map((r: WearablesStrictHttpResponse<WeeklyStatisticsModel>): WeeklyStatisticsModel => r.body)
    );
  }

}
