import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { UserInfoService } from '../auth-service/user-info-service';
import { TeamsService } from '../teams-service/teams.service';


@Injectable({
  providedIn: 'root',
})
export class TokenService {

  constructor(
    private authService: AuthService,
    private _userInfoService: UserInfoService,
    private _teamsService: TeamsService
  ) {

  }

  public async initService(): Promise<boolean> {
    await this.refreshTokenAsync();
    return true;
  }


  public async tokenExpired(): Promise<boolean> {
    var inTeams = await this._teamsService.inTeams();
    if(inTeams)
      return false;
    return await this.authService.tokenExpired();
  }


  public async getTokenAsync(source: string): Promise<string | null> {

    var inTeams = await this._teamsService.inTeams();
    if(inTeams){
      return await this._teamsService.getToken();
    }

    // If we have a token that is not expired, send it.
    if (!this.tokenExpired()) {     
      return await this.authService.token();
    }
    // only allow one call at a time through to potensially call token api
    return await this.refreshTokenAsync();
  }


  public async refreshTokenAsync(): Promise<string | null> {
  
    if (!this.tokenExpired()) {
      return await this.authService.token();
    }
    const userInfo = await this._userInfoService.getUserInfo();
    if (userInfo) {
      var loggedIn = await this.authService.refreshToken(false);
    }

    return await this.authService.token();
  }
}
