import { Injectable } from '@angular/core';
import { SecureStorageKey } from './models/secure-storage-key.enum';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class SecureStorageService {
	constructor(private storageService: StorageService) { 
	}
	
	public async set(key: SecureStorageKey, value: string): Promise<boolean> {
		return await this.storageService.set(key, value);
	}

	public async get(key: SecureStorageKey): Promise<string | null> {
		return await this.storageService.get(key);
	}

	public async remove(key: SecureStorageKey): Promise<boolean> {
		return await this.storageService.remove(key);
	}


	public async clear(): Promise<boolean> {
		return await this.storageService.clear();
	}

	public async keys(): Promise<Array<SecureStorageKey>> {
		return await this.storageService.keys();
	}

	
}
