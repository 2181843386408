/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrganizationBaseService } from '../organization-base-service';
import { OrganizationApiConfiguration } from '../organization-api-configuration';
import { OrganizationStrictHttpResponse } from '../organization-strict-http-response';

import { OrganizationSyncModel } from '../models/organization-sync-model';
import { syncSyncOrganizationsGet } from '../fn/sync/sync-sync-organizations-get';
import { SyncSyncOrganizationsGet$Params } from '../fn/sync/sync-sync-organizations-get';

@Injectable({ providedIn: 'root' })
export class OrganizationSyncService extends OrganizationBaseService {
  constructor(config: OrganizationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `syncSyncOrganizationsGet()` */
  static readonly SyncSyncOrganizationsGetPath = '/Sync/SyncOrganizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncSyncOrganizationsGet()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncSyncOrganizationsGet$Response(params?: SyncSyncOrganizationsGet$Params, context?: HttpContext): Observable<OrganizationStrictHttpResponse<Array<OrganizationSyncModel>>> {
    return syncSyncOrganizationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `syncSyncOrganizationsGet$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  syncSyncOrganizationsGet(params?: SyncSyncOrganizationsGet$Params, context?: HttpContext): Observable<Array<OrganizationSyncModel>> {
    return this.syncSyncOrganizationsGet$Response(params, context).pipe(
      map((r: OrganizationStrictHttpResponse<Array<OrganizationSyncModel>>): Array<OrganizationSyncModel> => r.body)
    );
  }

}
