/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VirtualHealthLibraryBaseService } from '../virtual-health-library-base-service';
import { VirtualHealthLibraryApiConfiguration } from '../virtual-health-library-api-configuration';
import { VirtualHealthLibraryStrictHttpResponse } from '../virtual-health-library-strict-http-response';

import { aiMediaQueryJsonPost } from '../fn/ai/ai-media-query-json-post';
import { AiMediaQueryJsonPost$Params } from '../fn/ai/ai-media-query-json-post';
import { aiMediaQueryPost } from '../fn/ai/ai-media-query-post';
import { AiMediaQueryPost$Params } from '../fn/ai/ai-media-query-post';
import { MediaQueryResponse } from '../models/media-query-response';

@Injectable({ providedIn: 'root' })
export class VirtualHealthLibraryAiService extends VirtualHealthLibraryBaseService {
  constructor(config: VirtualHealthLibraryApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `aiMediaQueryPost()` */
  static readonly AiMediaQueryPostPath = '/AI/MediaQuery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `aiMediaQueryPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aiMediaQueryPost$Response(params?: AiMediaQueryPost$Params, context?: HttpContext): Observable<VirtualHealthLibraryStrictHttpResponse<MediaQueryResponse>> {
    return aiMediaQueryPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aiMediaQueryPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aiMediaQueryPost(params?: AiMediaQueryPost$Params, context?: HttpContext): Observable<MediaQueryResponse> {
    return this.aiMediaQueryPost$Response(params, context).pipe(
      map((r: VirtualHealthLibraryStrictHttpResponse<MediaQueryResponse>): MediaQueryResponse => r.body)
    );
  }

  /** Path part for operation `aiMediaQueryJsonPost()` */
  static readonly AiMediaQueryJsonPostPath = '/AI/MediaQueryJson';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `aiMediaQueryJsonPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aiMediaQueryJsonPost$Response(params?: AiMediaQueryJsonPost$Params, context?: HttpContext): Observable<VirtualHealthLibraryStrictHttpResponse<string>> {
    return aiMediaQueryJsonPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aiMediaQueryJsonPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aiMediaQueryJsonPost(params?: AiMediaQueryJsonPost$Params, context?: HttpContext): Observable<string> {
    return this.aiMediaQueryJsonPost$Response(params, context).pipe(
      map((r: VirtualHealthLibraryStrictHttpResponse<string>): string => r.body)
    );
  }

}
