export const environment = {
	deepLinkURLBase:'https://vivascore.alula.health',
	appEnvironmentBaseUrl:'https://api.alulahealth.com/health/environment',
	oneSignalAppId: 'fc358462-7175-4b89-9bf5-13224240e909',
	environmentName: 'default',
	production: true,	
	webAppId : 'com.alulatechnologies.vitalscore',
	version: '1.179',
	build: 279,
	defaultLanguage: 'en',
	minimumAge: 18,
	availablelanguages: [
		{ code: 'en', text: 'English', dir: 'ltr' }
	//	{ code: 'ur', text: 'Urdu', dir: 'rtl' },
	//	{ code: 'it', text: 'Italiano', dir: 'ltr' },
	//	{ code: 'af', text: 'Afrikaans', dir: 'ltr' },
	  ],
	  imperialCountries: ['us', 'gb', 'lr', 'mm']
	
};

